@import url("https://fonts.googleapis.com/css2?family=New+Rocker&display=swap");

* {
  margin: 0px;
  padding: 0px;
  font-family: "calibri" !important;

  box-sizing: border-box;
}

.certificateTop {
  font-family: "New Rocker", cursive !important;
}

.char3 {
  transform: rotate(18deg);
}

#curve {
  fill: transparent;
}

/* *,
*::before,
*::after {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
} */

/* body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
} */

/* Ribbon */
.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #f26669;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #f26669;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  /* text-align: center; */
  text-indent: 6.2rem;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* dropdown menu */

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.shopping-cart {
  fill: black !important;
  height: 20px !important;
  width: 20px !important;
}

.add-item-to-cart {
  fill: black !important;
  height: 20px !important;
  width: 20px !important;
  animation: pop 0.3s ease-in-out;
}

.shopping-cart-nav {
  width: 23px;
  height: 23px;
  stroke: white;
  stroke-width: 0.5px;
}

.cart-item > svg > path {
  fill: white !important;
}

.app-navbar {
  flex-wrap: nowrap !important;
}

.app-nav-bar-items {
  display: flex;
  align-items: center;
  justify-content: center;

  position: inherit;
  height: 100%;
}

.heart-favor-nav {
  width: 23px;
  height: 23px;
  stroke: white;
  stroke-width: 0.5px;
}

.heart-item > svg > path {
  fill: white !important;
}

.app-header-nav-bar {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.app-symbolic-menu {
  display: flex;
  align-items: center;

  position: relative;
  height: 100%;
}

.my-favorite-icon,
.my-shopping-cart-icon {
  height: inherit;
  display: flex;
  align-items: center;
}
.heart-item {
  padding-top: 12px;
}

.profile-nav-bar {
  display: flex;
  align-items: center;
  height: 100%;
  border: 1px dashed;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.profile-nav-bar > svg {
  height: 38px;
  width: 38px !important;
}

#navbarNavDropdown {
  height: inherit;
}

.nav-list {
  display: flex;
  justify-content: center;
}

.nav-item {
  margin-top: 2rem;
  text-align: center;
  width: 26vw;
}

.nav-link {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  letter-spacing: 0.1rem;
  text-shadow: 0 0.2rem 0.5rem white;
}

.dropdown-menu-container {
  margin-top: 1rem;
  background-color: white;
  position: relative;
  z-index: 100;
  /* opacity: 0; */
  visibility: hidden;
  height: 0;
}

.nav-item:hover .dropdown-menu-container {
  opacity: 1;
  visibility: visible;
  height: auto;
}

.dropdown-item {
  text-align: left;
  padding: 2rem 2rem 0 2rem;
}

.dropdown-item:last-child {
  padding-bottom: 2rem;
}

.dropdownlink {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
}

.dropbtn {
  /* background-color: transparent; */
  color: white;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: default !important;

  display: flex;
  align-items: baseline;

  top: 0px;
  right: 0px;
  position: absolute;
  margin-top: 9px;
}

.dropbtn:focus {
  border: none;
  outline: none;
}

.dropbtn > a > svg {
  cursor: pointer;
}

.dropbtn:hover {
  height: 70px;
  display: flex;
  align-items: baseline;
}

.dropbtn a {
  display: flex;
  align-items: baseline;
}

.dropdown {
  height: 100%;
  position: initial;
  display: inline-block;
  transition: 0.5s;
}

.dropdown-content {
  /* z-index: 100 !important; */
  /* should be absolute with a backgound to become on top of other items on the page */
  top: 73px;
  color: black;
  border-radius: 5px;
  border: 0px solid #bfbfbf;

  /* height: 0; */
  /* overflow: hidden; */
  opacity: 0;
  transform: scale(0.98) translateX(0px) translateY(0px);
  visibility: hidden;
  /* transition: border height 0s; */
  /* transition: visibility 0.5s, opacity 0.5s linear, scale 0.2s ease-in-out,
    translateX 0.2s ease-in-out, translateY 0.2s ease-in-out; */
  transition: all 0.2s ease-in-out;
  transition-delay: 0.3s;

  position: absolute;
  right: 0px;
  /* background-color: #f9f9f9; */
  background: #f9f9f9 !important;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  /* background: rgba(244, 238, 238, 0.25); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px); */
  /* -webkit-backdrop-filter: blur(4px); */
  /* border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */
}

.dropdown-content a {
  color: black;

  text-decoration: none;
  display: block;
}

.dropdown-content span {
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.dropdown-content span > svg {
  width: 24px;
  height: 24px;
}

.dropdown-content span > a {
  padding-left: 16px;
}

.dropdown-content span:hover {
  /* background: rgba(241, 241, 241, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */
  /* background-color: #f1f1f1; */
  /* border-radius: 5px; */
  /* color: #5fb05f; */
  color: black;
  background-color: rgb(95, 176, 95, 0.5);
}

.dropdown-content span:hover path {
  /* fill: #5fb05f; */
  fill: black;
}

.dropdown-content span:hover a {
  /* color: #5fb05f; */
  color: black;
}

.dropdown:hover .dropdown-content {
  /* background-color: #f9f9f9; */
  /* background-color: green; */
  /* height: 431px; */
  background: rgba(244, 238, 238, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  transition-delay: 0s;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  opacity: 1;
  visibility: visible;
  transform: scale(1) translateX(0px) translateY(0px);
}

/* .dropdown:active .dropdown-content {
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;
} */

.dropdown:hover .dropbtn {
  height: 70px;
  display: flex;
  align-items: baseline;
}

.search-textbox {
  min-width: 200px;
  width: 100%;
}

.first-manu-item {
  padding: 20px 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #bfbfbf;
  background-color: transparent;
  display: grid;
  grid-template-rows: 1fr 1fr;
  cursor: context-menu;
  /* align-items: center; */
}
.first-manu-item:hover {
  background-color: transparent;
}
.first-manu-item span {
  background-color: transparent !important;
  padding: 0px 10px !important;
  cursor: default;
}
.first-manu-item span > a {
  padding-left: 0px;
  display: flex;
  align-items: center;
}
.first-manu-item > span {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
}
.menu-profile-camera {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -10px;

  border: 1px solid #bfbfbf;
  border-radius: 50%;
  background-color: white;

  padding: 3px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  /* cursor: pointer; */
}

.menu-profile-camera > svg > path {
  fill: gray;
}

.menu-profile-camera:hover path {
  fill: #09323b !important;
  /* background-color: #5fb05f; */
}

.menu-profile-camera > svg {
  width: 15px !important;
  height: 15px !important;

  /* cursor: context-menu; */
}

.first-manu-item span:hover {
  background-color: transparent !important;
}
.first-manu-item div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.menu-profile-icon {
  position: relative;
  /* background-color: #f9f9f9 !important; */
}
.menu-profile-icon-container {
  position: relative;
}

.menu-profile-icon svg path {
  fill: gray !important;
}

.menu-profile-icon svg {
  width: 50px;
  height: 50px;
}

.use-name-email > p {
  height: 100%;
  font-weight: 600;
}

.use-name-email {
  cursor: context-menu;
}

/* =================================Pop up message===========================================*/
HTML CSSResult Skip Results Iframe EDIT ON body {
  font-family: Arial, sans-serif;
  background: url(http://www.shukatsu-note.com/wp-content/uploads/2014/12/computer-564136_1280.jpg)
    no-repeat;
  background-size: cover;
  height: 100vh;
}

h1 {
  text-align: center;
  font-family: Tahoma, Arial, sans-serif;
  color: #06d85f;
  margin: 80px 0;
}

.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06d85f;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06d85f;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}

/* Modal */

.modal {
  position: fixed;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}

.modal-wrap {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: #fff;
  -ms-flex-item-align: center;
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
}

@media screen and (max-width: 500px) {
  .modal-wrap {
    width: calc(100% - 40px);
    padding-bottom: 15px;
  }
}

/* tick mark */
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  /* width: 30%;
  height: 30%; */
  width: 100px;

  margin: 40px auto 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  /* margin: 10% auto; */
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.3s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50vw #7ac142;
  }
}

/* Error tick mark */

.checkmark__circle-error {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: red;
  fill: none;
  animation: strokeerror 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-error {
  /* width: 30%;
  height: 30%; */
  width: 100px;
  margin: 40px auto 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  /* margin: 10% auto; */
  box-shadow: inset 0px 0px 0px red;
  animation: fillerror 0.3s ease-in-out 0.4s forwards,
    scaleerror 0.3s ease-in-out 0.9s both;
}

.checkmark__check-error {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: strokeerror 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes strokeerror {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scaleerror {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillerror {
  100% {
    box-shadow: inset 0px 0px 0px 50vw red;
  }
}

/* resizing */
.resizeme {
  padding: 0px 40px 20px 40px !important;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* MUI CSS */
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5fb05f !important;
}

.MuiInputLabel-root.MuiFormLabel-root.Mui-focused {
  color: #5fb05f !important;
}

.MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}

.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.Mui-focused {
  /* max-width: 100px; */
  text-overflow: clip;
}

.confirm-password-field fieldset legend {
  width: 98px;
}

.password-tooltip {
  white-space: pre-wrap;
}

.MuiTooltip-popper {
  white-space: pre-wrap;
  /* width: 100%; */
}

.MuiTooltip-tooltip {
  margin: 4px 0px 0px 0px !important;
  /* width: 100% !important;
  max-width: 100% !important; */
}

/* ReCaptcha */
/* 
.rc-anchor-error-msg-container {
  display: none !important;
} */

/* email verification error */

.error-svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 2s ease-in-out;
}
.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 2s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  50% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  50% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/* Course Combocox */
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  overflow-x: auto !important;
}

/* Datagrid setting */
.MuiDataGrid-filterFormDeleteIcon {
  flex: auto !important;
  width: 100px !important;
}

.MuiDataGrid-filterFormDeleteIcon button {
  width: 25px !important;
}

.team-checkout-form-container {
  height: 100%;
  padding-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.team-checkout-buyerEmail-container {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(242, 242, 242);
}

.left-section {
  padding: 50px;
}

.right-section {
  font-size: 14px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9fafc;
}

.team-checkout-card-information-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.team-checkout-exp-cvc-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team-checkout-exp-container {
  border-right: 1px solid #ddd;
  width: 100%;
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.team-checkout-exp-container .StripeElement {
  /* border-right: 1px solid #ddd; */
  width: 100%;
  /* padding: 10px;
  height: 100%;
  display: flex;
  align-items: center; */
}
.team-checkout-cvc-container {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  height: 100%;
  align-items: center;
}
.team-checkout-cvc-container img {
  width: 30px;
  height: 20px;
  margin-right: 5px;
}
.team-checkout-cvc-icon-container {
  display: flex;
  justify-content: right;
  align-items: center;
}
.team-checkout-card-number-container {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.team-checkout-card-number-icons-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: center;
}

.card-icons {
  display: flex;
  align-items: center;
  justify-content: right;
}

.card-icons img {
  width: 30px;
  height: 20px;
  opacity: 0.3; /* Make icons semi-transparent by default */
  margin-right: 5px;
}

.card-icons img.active {
  opacity: 1; /* Highlight the detected card brand */
}

.team-checkout-card-holder-name-container {
  margin-top: 20px;

  margin-bottom: 20px;
}
.team-checkout-card-holder-name-container input {
  padding: 10px;
  outline: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
}

.team-checkout-country-zip-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.team-checkout-country-zip-container select,
.team-checkout-country-zip-container input {
  border: none;
  padding: 10px;
  outline: none;
  width: 100%;
}

.team-checkout-country-container {
  border-bottom: 1px solid #ddd;
  padding-right: 20px;
}

.team-checkout-pay-button {
  background-color: #28a745;
  color: #000000;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}
.team-checkout-total-payment-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.team-checkout-items-list-container {
  gap: 5px;
  margin-bottom: 10px;
}
/* .right-section input,
.right-section select,
.StripeElement {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
} */

.stripe-footer {
  margin-top: auto; /* Pushes footer to the bottom */
  padding: 20px;
  text-align: center;
  background-color: #f6f9fc;
  border-top: 1px solid #ddd;
}

.stripe-footer p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.stripe-footer a {
  color: #000000;
  text-decoration: none;
}

.stripe-footer a:hover {
  text-decoration: none;
}
